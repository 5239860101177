import React from "react";
import CTAButton from "../CTAButton/CTAButton";
import doubleCheck from "../assets/images/check-double.svg";

const Feature = (props) => {
  const { title, description, CTAText, boxes } = props.data;
  const arrBoxes = props.arrBoxes;
  return (
      <article className="info-item requirements">
        <div className="info-item-details">
          <div className="info-item-details-left">
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className="info-item-details-right">
            <span>{CTAText}</span>
            <CTAButton />
          </div>
        </div>
        <div className="info-item-boxes">
          {Object.entries(boxes).map((dataItem, key) => {
            if (title !== 'Requirements') {
              return (
                  <div key={key} className={`box box-${dataItem[1]}`}>
                    <div className="box-container">
                      <span>{arrBoxes.indexOf(dataItem[0]) + 1}.</span>
                      {dataItem[0]}
                    </div>
                  </div>
              )
            } else {
              return (
                  <div key={key} className="box box-blue-dark requirements-box">
                    <div className="box-container requirements-box-container">
                      <span className="checkbox-big"><img src={doubleCheck} alt="Double Check Icon" /></span>
                      <p dangerouslySetInnerHTML={{__html:dataItem[1] }}></p>
                    </div>
                  </div>
              )
            }
          })}
        </div>
      </article>
  )
}

export default Feature;
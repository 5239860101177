import React from "react";
import Feature from "../Feature/Feature";

class FeaturesList extends React.Component {
  arrBoxes = () => {
    let arr = [];
    Object.values(this.props.data).map((dataItem) => {
      Object.keys(dataItem.boxes).map((itemKey) => {
        return (itemKey !== "first" && itemKey !== "second") ? arr.push(itemKey) : '';
      })
      return '';
    })
    return arr
  }

  render() {
    const data = this.props.data;
    const array = this.arrBoxes;
    return (
        <section className="info-list content-wrapper">
          { Object.values(data).map((dataItem, key) => {
            return (
                <Feature key={key} data={dataItem} arrBoxes={array()} />
            )
          })}
        </section>
    );
  }
}

export default FeaturesList;
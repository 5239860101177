import './App.css';
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import FeaturesList from "./components/FeaturesList/FeaturesList";
import {FEATURES_DATA} from "./components/constants";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <FeaturesList data={FEATURES_DATA}/>
    </div>
  );
}

export default App;

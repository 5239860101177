import React from "react";
import {FormInput} from "../FormInput/FormInput";

class Form extends React.Component {
  render() {
    return (
        <form action="">
          <FormInput />
          <FormInput />
          <FormInput />
          <input type="submit" name="submit" className="button submit-button"
                 value="Start earning WITH freelance" required />
        </form>
    );
  }
}

export default Form;
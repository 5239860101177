import React from "react";
import Form from "../Form/Form";
import {HERO_DATA} from "../constants";
import CTAButton from "../CTAButton/CTAButton";

const Hero = () => {
    const data = HERO_DATA;
    return (
      <section className="hero content-wrapper">
        <div className="hero-content">
          <div className="hero-content-left">
            <div className="title">
              <span className="header-small">{data.smallTitle}</span>
              <h1 className="blue">{data.title}</h1>
            </div>
            <div className="description">
              <h2 dangerouslySetInnerHTML={{__html: data.sloganHTML}}></h2>
              <p>{data.description}</p>
            </div>
            <CTAButton />
          </div>
          <div id="aForm" className="hero-content-right">
            <div className="box box-blue-dark form-container">
              <div className="form-title">
                <span className="form-notificator">{data.formNotification}</span>
                <h2 className="form-title-header">{data.formTitle}</h2>
              </div>
              <Form />
            </div>
          </div>
        </div>
      </section>
    )
}

export default Hero;
import React from "react";
import logo from '../assets/images/logo-icon.svg'

const Header = () => {
  return (
      <header>
        <div className='navbar content-wrapper'>
          <a href="/#" className="logo">
            <img src={ logo } width="48" height="44" alt="Devslopes.com logo" />
            Devslopes
          </a>
        </div>
      </header>
  )
}

export default Header;


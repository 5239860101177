import React from "react";

class CTAButton extends React.Component {

  handleCTAClick = () => {
    const formRect = document.getElementById('aForm').getBoundingClientRect();
    window.scrollTo(null, formRect.top + window.scrollY - 38);
  }

  render() {
    return (
        <button type="button" className="button call-to-action" onClick={this.handleCTAClick}>
          GET YOUR EARLYBIRD DISCOUNT
          <span>🌅</span>
          <span>🐦</span>
          <span>🏷</span>
          <span>🎉</span>
        </button>
    );
  }
}

export default CTAButton;
export const HERO_DATA = {
  smallTitle: 'Full-time',
  title: 'Freelancer Academy',
  sloganHTML: 'Earn 100K - 300K a year <span>💰</span><span>💰</span><span>💰</span><span>🚀</span>',
  description: 'Coaching for serious hustlers who want to make 100K+ a year as a freelance developer.',
  formNotification: 'Coming soon',
  formTitle: 'Join the Waitlist and get an Earlybird discount'
};

export const FEATURES_DATA = {
  requirements: {
    title: 'Requirements',
    description:
        'This is not an online course. The moment you enroll ' +
        'you will be closing jobs and making money. If you aren’t ready to ' +
        'start earning NOW this program is not for you.',
    CTAText: 'Want to start earning NOW?',
    boxes: {
      first: 'You have a full or part-time job as a developer',
      second: 'You have made over <strong>$1000</strong> in freelance work as a developer',
    },
  },
  about: {
    title: 'What is so great about the Academy?',
    description:
        'This is not an online course. The moment you enroll ' +
        'you will be closing jobs and making money. If you aren’t ready to ' +
        'start earning NOW this program is not for you.',
    CTAText: 'Interested enough?',
    boxes: {
      'mentorship': 'blue-dark',
      'coaching': 'red-dark',
      'sales training': 'yellow-medium',
    },
  },
  services: {
    title: 'Services to get clients',
    description:
        'This is not an online course. The moment you enroll ' +
        'you will be closing jobs and making money. If you aren’t ready to ' +
        'start earning NOW this program is not for you.',
    CTAText: 'A dream of any freelancer!',
    boxes: {
      'upwork': 'green-dark',
      'fiverr': 'yellow-medium',
      'ads': 'purple-dark',
    },
  },
  technology: {
    title: 'Technology at your service',
    description:
        'This is not an online course. The moment you enroll ' +
        'you will be closing jobs and making money. If you aren’t ready to ' +
        'start earning NOW this program is not for you.',
    CTAText: 'Should have already claimed a discount!',
    boxes: {
      'leads': 'purple-medium',
      'landing pages': 'red-dark',
      'pipeline': 'green-light',
    },
  },
  bonus: {
    title: 'It would not be us if we didn\'t give some bonus stuff!',
    description:
        'This is not an online course. The moment you enroll ' +
        'you will be closing jobs and making money. If you aren’t ready to ' +
        'start earning NOW this program is not for you.',
    CTAText: 'It is all worth it, no fools!',
    boxes: {
      'closing $10,000 deals': 'yellow-medium',
      'legal': 'blue-light',
      'project management': 'red-light',
      'building a team': 'green-medium',
    },
  },
};